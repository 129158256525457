import moment from "moment";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, Checkbox, Button, Icon as IconS } from "semantic-ui-react";
import { Icon, Popover } from "components/common";
// import EmailAllButton from "components/common/EmailAllButton";
import { downloadFile } from "components/common/DownloadButton/utils";
import { getExtension, createSynthId } from "helpers/utils";
import LABEL from "constants/label";
import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS } from "analytics/constants";

import Share from "./Share";
import useSortableData from "./useSortableData";
import "./styles.scss";

/**
 * Vignettes
 *
 * @param  {arrayOf(shape)} vignettes vignettes details
 * @param  {string} caseNumber case number
 * @param  {string} clientDescription case client description
 * @param  {string} caseOpenDate case start date
 * @param  {string} caseType type of case
 * @param  {string} shortDescription case short desc
 *
 * @return vignettes details table
 *
 */

const Vignettes = ({
  vignettes,
  caseNumber,
  clientDescription,
  caseOpenDate,
  caseType,
  shortDescription,
  projectId,
  rank,
  description,
  textDescription,
  caseVignettesStatus
}) => {
  const { VIGNETTES } = LABEL;

  const MAX_COUNT = 8;
  const message = (maxCount) => `Select up to ${maxCount} documents to share via email.`;

  const [docsCount, setDocsCount] = useState(0);
  const [textOnButton, setTextOnButton] = useState("");

  const [checkedList, setCheckedList] = useState([]);

  //  vignettes data with other case fields
  const mergedData = vignettes.map((item) => {
    return {
      ...item,
      caseNumber,
      caseOpenDate,
      clientDescription,
      shortDescription,
      caseType,
      textDescription,
      description,
      caseVignettesStatus
    };
  });
  const [models] = useState(mergedData);

  const isChecked = (attachment) => {
    const attSynthId = createSynthId(attachment);
    return checkedList.some((c) => createSynthId(c) === attSynthId);
  };

  // table body checkbox click handler

  const attachmentClickHandler = (attachment) => {
    if (isChecked(attachment)) {
      setCheckedList(checkedList.filter((c) => createSynthId(c) !== createSynthId(attachment)));
    } else {
      const newList = [...checkedList];
      newList.push(attachment);
      setCheckedList(newList);
    }
  };

  // to check if all docs are slected via checkbox
  const allDocsSelected = () => models.every((attachment) => isChecked(attachment));

  // to check if some docs are slected via checkbox
  const someDocsSelected = () => models.some((attachment) => isChecked(attachment));

  // table header checkbox click handler

  const headerClickHandler = () => {
    if (allDocsSelected()) {
      setCheckedList([]);
    } else {
      setCheckedList(models);
    }
  };

  // when items are checked or unchecked
  useEffect(() => {
    setDocsCount(checkedList.length);
    let text = "All";
    if (checkedList.length !== models.length) {
      if (checkedList.length > 0) {
        text = checkedList.length;
      } else {
        text = "";
      }
    }
    setTextOnButton(text);
  }, [checkedList]);

  // sort by

  const { items, requestSort, sortConfig } = useSortableData(models);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return false;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  // Sort by fileName on page load
  useEffect(() => {
    requestSort("fileName");
  }, []);

  return (
    <Table compact="very" verticalAlign="middle" className="mt-h member-table member-table-with-header">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={10} className="pl-2 full-width ">
            {/* Table header checkbox */}
            {/* <Checkbox
              data-testid="checkbox-header"
              checked={allDocsSelected()}
              indeterminate={someDocsSelected() && !allDocsSelected()}
              onMouseDown={(e) => {
                headerClickHandler(e);
              }}
              onChange={() => {}}
            /> */}
            <span
              data-testid="fileName"
              className="cursor-pointer"
              role="button"
              tabIndex="0"
              onKeyDown={() => {}}
              onClick={() => requestSort("fileName")}
            >
              {VIGNETTES.DocumentName}{" "}
              {/* {getClassNamesFor("fileName") && <IconS name={`arrow ${getClassNamesFor("fileName")}`} />} */}
            </span>
            <span className="vignettes__button-container">
              {/* Email button table header */}
              {/* <EmailAllButton docs={models.filter((model) => model.checked)} projectId={projectId}> */}

              {/* <Button
                data-testid="download-all-btn"
                className={`vignettes__button mr-1 ${docsCount > MAX_COUNT ? "vignettes__button--disabledBtn" : ""}`}
                size="mini"
                compact
                disabled={!someDocsSelected()}
              >
                {VIGNETTES.Download} {textOnButton}
              </Button> */}

              {/* <EmailAllButton docs={checkedList} projectId={projectId}>
                {(handleEmailAllSend) => (
                  <Popover
                    on="hover"
                    position="bottom center"
                    popper={{ className: "xs case-tooltip" }}
                    inverted
                    trigger={
                      <Button
                        data-testid="email-all-btn"
                        className={`vignettes__button mr-1 ${
                          docsCount > MAX_COUNT ? "vignettes__button--disabledBtn" : ""
                        }`}
                        size="mini"
                        compact
                        onClick={handleEmailAllSend}
                        disabled={!someDocsSelected()}
                      >
                        {VIGNETTES.Email} {textOnButton}
                      </Button>
                    }
                  >
                    {docsCount > MAX_COUNT ? VIGNETTES.EXCEEDED_MAX : message(MAX_COUNT)}
                  </Popover>
                )}
              </EmailAllButton> */}
            </span>
          </Table.HeaderCell>
          <Table.HeaderCell width={3} textAlign="center" className="md-none">
            <span
              data-testid="numberOfDownloads"
              className="cursor-pointer"
              role="button"
              tabIndex="0"
              onKeyDown={() => {}}
              onClick={() => requestSort("numberOfDownloads")}
            >
              {VIGNETTES.downloadcount}{" "}
              {getClassNamesFor("numberOfDownloads") && (
                <IconS name={`arrow ${getClassNamesFor("numberOfDownloads")}`} />
              )}
            </span>
          </Table.HeaderCell>
          <Table.HeaderCell className="pr-2 md-none" width={1}>
            {VIGNETTES.Actions}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body style={{ backgroundColor: "white" }}>
        {items?.map((attachment) => {
          return (
            <Table.Row key={attachment.filename} verticalAlign="top">
              <Table.Cell className="pl-2 first">
                <div className="vignettes__fileName file-row d-flex">
                  {/* Table body checkbox */}
                  {/* <Checkbox
                    data-testid="checkbox-body"
                    checked={isChecked(attachment)}
                    onMouseDown={() => {
                      attachmentClickHandler(attachment);
                    }}
                    onChange={() => {}}
                  /> */}
                  <Icon name={getExtension(attachment.imagename)} />

                  <span className="fw-normal word__break--breakAll">{attachment.filename}</span>
                </div>
                <div className="vignettes__lastUpdatedBy">
                  Updated on {moment(attachment.lastupdatedate).format("MMM DD, YYYY")}
                </div>
              </Table.Cell>
              <Table.Cell textAlign="center" className="md-none">
                {attachment.downloadcount}
              </Table.Cell>
              <Table.Cell className="pr-2 action">
                {/* Share button */}
                <span className="vignettes__shareIcon">
                  <Share attachment={attachment} projectId={projectId} docRank={rank} />
                </span>

                {/* Single case download button */}
                <Popover
                  on="hover"
                  position="bottom center"
                  inverted
                  popper={{ className: "sm case-tooltip" }}
                  size="tiny"
                  trigger={
                    <span
                      className="ml-1 md-none"
                      data-testid="download-single"
                      role="button"
                      tabIndex="0"
                      onKeyDown={() => {}}
                      onClick={() => {
                        sendEventData(TRIGGERS.DOWNLOAD, EVENTS.VIEW_KP_KB_CP, {
                          kpid: attachment.attachmentId,
                          cpid: attachment.caseNumber,
                          rank
                        });
                        downloadFile(attachment.materialId, attachment.attachmentId, attachment.kp_cms_id);
                      }}
                    >
                      <Icon name="save_outlined" className="cursor-pointer" size={12} />
                    </span>
                  }
                >
                  {VIGNETTES.Download}
                </Popover>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

Vignettes.propTypes = {
  vignettes: PropTypes.arrayOf(PropTypes.shape()),
  caseNumber: PropTypes.string,
  clientDescription: PropTypes.string,
  caseOpenDate: PropTypes.string,
  caseType: PropTypes.string,
  shortDescription: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  rank: PropTypes.number,
  textDescription: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  caseVignettesStatus: PropTypes.string
};

Vignettes.defaultProps = {
  vignettes: [{}],
  caseNumber: "",
  clientDescription: "",
  caseOpenDate: "",
  caseType: "",
  shortDescription: "",
  rank: 0,
  caseVignettesStatus: ""
};

export default Vignettes;
