/* eslint-disable no-underscore-dangle */
import LABEL from "constants/label";
import Hashes from "jshashes";
import "./analytics.config";
import { PAGE_NAME } from "./constants";

const { Expertise } = LABEL.EXPERTISE;

/**
 * @returns the hashed email id of the logged in user
 */

const getStaffEmailID = () => {
  return new Hashes.SHA256().hex(window.$userEmail?.toLowerCase());
};

/**
 * @returns {string} page name
 */
export const getPageName = () => {
  return window.$tabName === Expertise ? `${PAGE_NAME}_expertise` : PAGE_NAME;
};

/**
 * @param {string} trigger what caused the event
 * @param {string} pageUrl page url on which the event was trigged
 * Creates the digitalData object and populates with required values
 */

const createAnalyticsObject = (trigger, otherData = null) => {
  const pageObj = {
    pageInfo: {
      pageName: getPageName(),
      staffSystemID: getStaffEmailID(),
      pageURL: window.location.href
    },
    searchInfo: {
      searchPageNumber: otherData?.searchPageNumber
    }
  };
  const acrossTabObj = {
    profile: {
      profileName: window.$profileName,
      profileTitle: window.$profileTitle,
      profileOffice: window.$profileOffice
    }
  };

  let search = {};

  if (otherData) {
    const {
      kpid,
      kbid,
      cpid,
      filterLabel,
      filterLevel,
      filterCat,
      sortOrder,
      preSortTerm,
      preFilterTerm,
      term,
      type,
      typeHeadOptionCase,
      typeHeadOptionPeople,
      docRank,
      sectionName,
      subCategory,
      campaignSource,
      campaignText
    } = otherData;
    window.digitalData = {
      ...window.digitalData,
      kpid,
      kbid: kbid || "NA",
      cpid,
      sortOrder,
      preSortTerm,
      preFilterTerm,
      sectionName,
      subCategory,
      campaignSource,
      campaignText
    };
    search = {
      filterLabel,
      filterLevel,
      filterCat,
      term,
      type,
      typeHeadOptionCase: typeHeadOptionCase || "N/A",
      typeHeadOptionPeople: typeHeadOptionPeople || "N/A",
      searchResultsTermRank: docRank
    };
  }
  window.digitalData = {
    ...window.digitalData,
    page: { ...pageObj },
    acrossTabs: { ...acrossTabObj },
    newTabName: otherData?.newTabName || window.$tabName,
    link: { trigger },
    search
  };
};

/**
 *
 * @param {string} trigger what caused the event
 * @param {string} pageUrl page url on which the event was trigged
 * @param {string} eventName name of the event
 */

const sendEventData = (trigger, eventName, otherData = null) => {
  createAnalyticsObject(trigger, otherData);
  if (window._satellite && window._satellite.track) {
    window._satellite.track(eventName);
    window._satellite.pageBottom();
  }
  window.digitalData = null;
};

export default sendEventData;
