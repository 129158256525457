import { useState, useEffect } from "react";
import { Table } from "semantic-ui-react";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toastError } from "helpers/utils";
import LABEL from "constants/label";
import { fetchTeamMemberDetails } from "components/App/handlers/handlers";
import Member from "../Member/Member";

/**
 * TeamMember
 *
 * @param  {object} team team member details
 *
 * @return member details table
 */

const Team = ({ team, origin, sortBy, getFilterterm, kpCmsId, headerWidth, className }) => {
  const { COMMON } = LABEL;
  const dispatch = useDispatch();
  const { memberListError, memberList } = useSelector((state) => state);

  useEffect(() => {
    if (team) {
      const list = [];
      team.map(({ hrEmployeeId, hremployeeid }) => list.push(hrEmployeeId?.toString() || hremployeeid?.toString()));
      fetchTeamMemberDetails(list, dispatch);
    }
  }, [team]);

  // Error toast msg

  const [errorCalled, setErrorCalled] = useState(false);
  let errorCalledTemp;

  const onApiError = () => {
    if (!errorCalled && !errorCalledTemp && memberListError?.message) {
      toastError(0, LABEL.ERRORS.NetworkError, LABEL.ERRORS.PeopleErrMsg);
      setErrorCalled(true);
      errorCalledTemp = true;
    }
  };

  useEffect(() => {
    onApiError();
  }, [memberListError]);

  return (
    <Table className="caseTeam-table member-table" striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={headerWidth[0]}>{COMMON.Name}</Table.HeaderCell>
          {origin === "case" ? (
            <Table.HeaderCell width={headerWidth[1]}>{COMMON.CasePosition}</Table.HeaderCell>
          ) : (
            <Table.HeaderCell width={headerWidth[1]}>{COMMON.Title}</Table.HeaderCell>
          )}
          <Table.HeaderCell width={headerWidth[2]}>{COMMON.Office}</Table.HeaderCell>
          <Table.HeaderCell width={1}>&nbsp;</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {team?.map((member, index) => (
          <Member
            key={member.hremployeeid ?? member.hrEmployeeId}
            member={member}
            errorCallback={() => onApiError()}
            origin={origin}
            index={index}
            sortBy={sortBy}
            getFilterterm={getFilterterm}
            kpCmsId={kpCmsId}
            className={className}
            people={memberList[member.hremployeeid ?? member.hrEmployeeId]}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

Team.defaultProps = {
  team: [{}],
  origin: "",
  sortBy: "",
  getFilterterm: noop,
  kpCmsId: "",
  headerWidth: [5, 3, 3, 2],
  className: ""
};

Team.propTypes = {
  team: PropTypes.arrayOf(PropTypes.shape()),
  origin: PropTypes.string,
  sortBy: PropTypes.string,
  getFilterterm: PropTypes.func,
  kpCmsId: PropTypes.string,
  headerWidth: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string
};

export default Team;
