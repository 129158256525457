import PropTypes from "prop-types";
import { Icon, Popover, Menu } from "components/common";
import { copyToClipboard } from "helpers/utils";
import LABEL from "constants/label";
import { emailSingleVignette, emailSingleAttachment } from "components/common/EmailAllButton/utils";
import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS } from "analytics/constants";
import CONFIG from "constants/config";

const { VIGNETTES } = LABEL;

/**
 * Share
 *
 * @param  {object} attachment single case
 *
 * @return Share component
 */

const Share = ({ attachment, projectId, docRank }) => {
  const casePage = (id) => `${CONFIG.cases.casePageUrl}${id}`;

  /**
   * The below code is commented because this feature will be needed in future.
   * The third Item in the 'menuItems' array is also a part of the same feature.
   *
   * Uncomment when needed.
   */

  // const vignetteLink = (term, attachmentId) =>
  //   `newnavigator.bcg.com/cases?query=${term}&viewState=expanded&attachmentId=${attachmentId}`;

  const menuItems = [
    {
      name: VIGNETTES.ShareViaEmail,
      onClick: () => {
        sendEventData(TRIGGERS.VIGNETTE.SHARE_VIA_EMAIL, EVENTS.VIEW_KP_KB_CP, {
          kpid: attachment.kp_cms_id,
          cpid: projectId,
          docRank
        });
        if (attachment.projectId) {
          emailSingleVignette(
            attachment.filename,
            attachment.projectId,
            attachment.attachmentId,
            attachment.caseType,
            attachment.shortDescription
          );
          return;
        }
        if (
          (attachment.kp_cms_id || (attachment.attachmentId && attachment.attachmentId !== 0)) &&
          attachment.fileName !== "" &&
          !attachment.materialURL
        ) {
          emailSingleAttachment(
            attachment.filename,
            attachment.attachmentId,
            attachment.materialId,
            attachment.textDescription || attachment.description,
            attachment.kp_cms_id
          );
        }
      }
    },
    {
      name: VIGNETTES.CopyLinkToCasePage,
      onClick: () => {
        sendEventData(TRIGGERS.VIGNETTE.COPY_TO_CASE_PAGE, EVENTS.VIEW_KP_KB_CP, {
          kpid: attachment.kp_cms_id,
          cpid: projectId,
          docRank
        });
        copyToClipboard(casePage(attachment?.attachmentId || projectId));
      },
      copiedText: VIGNETTES.Copied
    }
    // {
    //   name: VIGNETTES.CopyLinkToVignettes,
    //   onClick: () => {
    //     sendEventData(TRIGGERS.VIGNETTE.COPY_TO_VIG, EVENTS.VIEW_KP_KB_CP, {
    //       kpid: attachment.kpCmsId,
    //       cpid: projectId
    //     });
    //     copyToClipboard(vignetteLink(attachment.projectId, attachment.attachmentId));
    //   },
    //   copiedText: VIGNETTES.Copied
    // }
  ];

  const trigger = (
    <span data-testid="share" className="cursor-pointer">
      <Popover
        trigger={
          <span>
            <Icon name="dots-connected-filled" size={12} />
          </span>
        }
        on="hover"
        inverted
        position="bottom center"
        size="tiny"
        popper={{ className: "xs case-tooltip" }}
      >
        {VIGNETTES.Share}
      </Popover>
    </span>
  );
  return <Menu menuItems={menuItems} trigger={trigger} />;
};

Share.propTypes = {
  attachment: PropTypes.shape(),
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  docRank: PropTypes.number
};

Share.defaultProps = {
  attachment: {},
  projectId: "1",
  docRank: 0
};

export default Share;
